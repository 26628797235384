import { AnyAction, createListenerMiddleware, isAnyOf, isFulfilled } from '@reduxjs/toolkit';
import i18next from 'i18next';

import { Effect } from 'features/app/types';
import {
  selectActiveCompany,
  selectApplicationsList,
  selectCompanyById
} from 'features/applications/selectors';
import { setActiveCompany } from 'features/auth/slice';
import { RootState } from 'store';
import { Languages } from 'translations/translations';
import trackers, { ApplicationSubmit } from 'utils/tracking';

import { createApplication, deleteApplication, submitPipedriveApplication, updateApplication } from './api';
import {
  getStepsArrayByFlow,
  sequences,
  validateStepAgainsResponse,
  getLatestStep,
  Flows
} from './forms/sequences';
import {
  selectCapId,
  selectCompletionIndex,
  selectNavigateTo,
  selectProduct,
  selectProductPayload,
  selectStepsSequence,
  selectApplicationIsLoading,
  selectCompany,
  selectApplicationData,
  selectIsFarm,
  selectFarm
} from './selectors';
import {
  setApplicationAmount,
  setCompanyData,
  setApplicationConsent,
  setLoansAdditionalInfo,
  setActiveStep,
  setCompletionIndex,
  setStepsSequence,
  setLoading,
  setCapId,
  submitApplicationAsync,
  setApplication
} from './slice';
import { ApplicationState, STEPS } from './types/applicationTypes';
import { postMessages } from './utils';

const sendToGoCredits = (state: ApplicationState) =>
  // TODO: ignore type checks until api is updated with new types
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  state.application.product !== 'RBF_PRODUCT';

const capIdFalsyOnContactPersonSet = (action: AnyAction, currentState: unknown) => {
  if (action.type === setApplicationConsent.type) {
    const capId = selectCapId((currentState as RootState).application);

    if (!capId) {
      return true;
    }
  }

  return false;
};

const createApplicationEffect: Effect = async (_action, { getState, dispatch }) => {
  const state = getState() as RootState;
  const { application } = state.application;

  if (!selectApplicationIsLoading(state.application)) {
    try {
      dispatch(setLoading(true));

      if (sendToGoCredits(state.application)) {
        const result = await dispatch(
          createApplication.initiate({
            content: { 'application/json': application as Record<string, unknown> }
          })
        ).unwrap();

        if (result.capId) {
          dispatch(setCapId(result.capId));
        }
      } else if (application.type) {
        const pipedriveProduct = selectProductPayload(state.application)(application.type);

        const applicationData: ApplicationSubmit = {
          locality: i18next.language as Languages,
          product: application.product ?? '',
          subproduct: application.subProduct ?? '',
          enhanced_conversions: {
            country: application.generalInfo?.company?.country ?? '',
            phone_number: application.generalInfo?.applicant?.phoneNumber ?? '',
            email: application.generalInfo?.applicant?.email ?? ''
          }
        };

        trackers.updateApplicationSubmitted(applicationData, { cap_product_type: application?.type });
        await dispatch(submitPipedriveApplication.initiate(pipedriveProduct)).unwrap();
      }
    } finally {
      postMessages.postCreationFromCapEvent(application);
      dispatch(setLoading(false));
    }
  }
};

const updateApplicationEffect: Effect = async (_action, { getState, dispatch }) => {
  const state = getState() as RootState;

  const capId = selectCapId(state.application);

  if (sendToGoCredits(state.application) && capId) {
    const payload = selectApplicationData(state.application);

    await dispatch(
      updateApplication.initiate({
        content: { 'application/json': payload as Record<string, unknown> },
        capId
      })
    ).unwrap();
  }
};

/**
 * Create a listener middleware
 * In development mode will log out all sync errors to console
 */
const listenerMiddleware = createListenerMiddleware({
  onError: process.env.NODE_ENV === 'development' ? console.error : undefined
});

/**
 * Attach listener
 * Amount step creates application
 * Will trigger only if capIdFalsyOnContactPersonSet doesn't exist in state
 */
listenerMiddleware.startListening({
  predicate: capIdFalsyOnContactPersonSet,
  effect: createApplicationEffect
});

/**
 * Generic step updates application with application data saved in state
 * Will trigger update if applicationNumber does exist in state
 */
listenerMiddleware.startListening({
  predicate: isFulfilled(submitApplicationAsync),
  effect: updateApplicationEffect
});

/**
 * Success handling for application flow (continue to next step in flow)
 */

/**
 * Match any async thunks actions fulfilled state or Amount update in state
 */
const navigateToNextStep = (action: AnyAction) => {
  if (
    action.type === setApplicationAmount.type ||
    action.type === setLoansAdditionalInfo.type ||
    action.type === setCompanyData.type
  ) {
    return true;
  }

  const apiEndpoints = [
    createApplication.matchFulfilled(action),
    updateApplication.matchFulfilled(action),
    submitPipedriveApplication.matchFulfilled(action)
  ];

  return apiEndpoints.some(Boolean);
};

/**
 * If request was succesful handles navigation logic for next button and stepper control
 */
const navigateToNextStepEffect: Effect = (_action, { dispatch, getState }) => {
  const state = getState() as RootState;

  const { application } = state;

  const sequence = selectStepsSequence(application);
  const currentCompletionIndex = selectCompletionIndex(application);
  const navigateTo = selectNavigateTo(application);

  if (navigateTo) {
    const nextIndex = sequence.indexOf(navigateTo) - 1;

    if (currentCompletionIndex < nextIndex) {
      dispatch(setCompletionIndex(nextIndex));
    }

    trackers.setFlowStep(navigateTo, { cap_product_type: application?.application?.type ?? '(not set)' });
    dispatch(setActiveStep(navigateTo));
  }
};

listenerMiddleware.startListening({
  predicate: navigateToNextStep,
  effect: navigateToNextStepEffect
});

const navigateToLastFilledInStep: Effect = (_action, { dispatch, getState }) => {
  const isInApplicationFlow = window.location.pathname.includes('new-application');

  if (isInApplicationFlow) {
    const state = getState() as RootState;

    const { application } = state;
    const product = selectProduct(application);
    const isFarmer = selectIsFarm(state);

    const urlParams = new URLSearchParams(window.location.search);
    const queryStep = urlParams.get('step');

    if (product) {
      const selectedSequence = getStepsArrayByFlow(
        product as Flows,
        sequences,
        application.application.type,
        Boolean(application.application.capId),
        isFarmer
      );
      const latestValidStep = validateStepAgainsResponse(
        queryStep,
        application.application,
        sequences[product]
      );
      const latestStep = getLatestStep(application.application, sequences[product]);
      const completedIndex = selectedSequence.findIndex((step) => step === latestStep) - 1;

      trackers.setFlowStep(latestValidStep, {
        cap_product_type: application?.application?.type ?? '(not set)'
      });

      dispatch(setStepsSequence(selectedSequence));
      dispatch(setActiveStep(latestValidStep as STEPS));
      dispatch(setCompletionIndex(completedIndex));
    }
  }
};

listenerMiddleware.startListening({
  predicate: isAnyOf(setApplication),
  effect: navigateToLastFilledInStep
});

const updateSelectedCompanyOnDelete: Effect = (_action, { dispatch, getState }) => {
  const state = getState() as RootState;

  const applications = selectApplicationsList(state);
  const selectedActiveCompany = selectActiveCompany(state);

  const activeCompanyExists = applications?.some(
    (application) => (application.company?.code ?? application.farm?.code) === selectedActiveCompany?.id
  );

  if (!activeCompanyExists) {
    dispatch(setActiveCompany(undefined));
  }
};

listenerMiddleware.startListening({
  predicate: (action) => deleteApplication.matchFulfilled(action),
  effect: updateSelectedCompanyOnDelete
});

const updateSelectedCompanyOnApplicationCreateOrUpdate: Effect = (_action, { dispatch, getState }) => {
  const state = getState() as RootState;

  const company = selectCompany(state.application);
  const farm = selectFarm(state.application);
  const activeCompany = selectActiveCompany(state);

  const aggregateCompany = selectCompanyById(state)(company?.code ?? farm?.code ?? '');

  if (aggregateCompany) {
    dispatch(
      setActiveCompany({
        ...aggregateCompany,
        id: company?.code ?? '',
        name: company?.name ?? '',
        country: company?.country ?? ''
      })
    );

    return;
  }

  dispatch(
    setActiveCompany({
      ...activeCompany,
      id: company?.code ?? activeCompany.id ?? '',
      name: company?.name ?? activeCompany.name ?? '',
      country: company?.country ?? activeCompany.country ?? '',
      roles: ['PROSPECT']
    })
  );
};

listenerMiddleware.startListening({
  predicate: (action) => updateApplication.matchFulfilled(action) || createApplication.matchFulfilled(action),
  effect: updateSelectedCompanyOnApplicationCreateOrUpdate
});

export default listenerMiddleware;
